const routes = [
    {
        path: '/catalog/products',
        name: 'products',
        meta: {
            reqAuth: true,
            gate: 'view_products_list'
        },
        component: () => import('../products/views/Products.vue')
    },
    {
        path: '/catalog/add-product',
        name: 'add-product',
        meta: {
            reqAuth: true,
            gate: 'add_product'
        },
        component: () => import('../products/views/AddProduct.vue')
    },
    {
        path: '/catalog/edit-product/:uuid',
        name: 'edit-product',
        meta: {
            reqAuth: true,
            gate: 'edit_product'
        },
        component: () => import('../products/views/EditProduct.vue')
    },
    {
        path: '/catalog/categories',
        name: 'categories',
        meta: {
            reqAuth: true,
            gate: 'view_category_list'
        },
        component: () => import('../categories/views/Categories.vue')
    },
    {
        path: '/catalog/manufacturers',
        name: 'manufacturers',
        meta: {
            reqAuth: true,
            gate: 'view_manufacturers_list'
        },
        component: () => import('../manufacturers/views/Manufacturers.vue')
    },
    {
        path: '/catalog/attributes',
        name: 'attributes',
        meta: {
            reqAuth: true,
            gate: 'view_attributes_list'
        },
        component: () => import('../attributes/views/Attributes.vue')
    },
    {
        path: '/catalog/dimensions',
        name: 'dimensions',
        meta: {
            reqAuth: true,
            gate: 'view_dimensions_list'
        },
        component: () => import('../dimensions/views/Dimensions.vue')
    }
];

export default routes;
